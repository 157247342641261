import React, {Component, Fragment, useEffect, useState} from 'react';
import {NavItem, NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import {useStore} from "../../models/RootStore";
import {observer} from "mobx-react-lite";

const LoginMenu = (props) => {
    
    const {authorization} = useStore();
    const registerPath = `/account/register`;
    const loginPath = `/account/login`;
    const profilePath = `account`;
    const logoutPath = `/`;
    
    useEffect(() => {
        
        
    }, [authorization]);
    
    return (
        <>
            {!authorization.isLoggedIn ? anonymousView(registerPath, loginPath) : authenticatedView(authorization.user?.username, profilePath, logoutPath)}
        </>
    )

   function  authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            <div className="gap-4 list-none flex">
                {authorization.isLoggedIn}
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink replace tag={Link} onClick={authorization.logout} className="text-dark" to={logoutPath} state={{local: true}}>Logout</NavLink>
                </NavItem>
            </div>

        </Fragment>);
    }

    function anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <div className="gap-4 list-none flex">
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} onClick={authorization.logout} className="text-dark" to={loginPath}>Login</NavLink>
                </NavItem>
            </div>
        </Fragment>);
    }
}

export default observer(LoginMenu);