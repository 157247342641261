import React, {useEffect, useState} from 'react';
import {Row} from "./Row";
import FileUploader from "./FileUploader";
import {useNavigate, useParams,useLocation} from 'react-router-dom';
import {observer} from "mobx-react-lite";
import {useStore} from "../../models/RootStore";

const Explorer = ()=> {
    const {setPageTitle, authorization} = useStore();
    
    const {pathname} = useLocation();   
    const [folderPath, setFolderPath] = useState([]);
    const [folderItems, setFolderItems] = useState([]);
    const [loading, setLoading] = useState(true);
   
    const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
    const navigate = useNavigate();
   
    useEffect(() => {
        authorization.getUser();
        
        const basePath = '/my-storage';
        let path = "/"
        if (pathname.startsWith(basePath)) {
            path = pathname.substring(basePath.length) || '/';
            path = decodeURIComponent(path);
        }
        
        setFolderPath(path);
        getFolderItems(path);

        setPageTitle("My Storage - " + path);
    }, [pathname]);
    
    function changeFolder(newFolderPath) {
        navigate(`/my-storage${newFolderPath || "/"}`);
    }
    async function getFolderItems(folder) {
        if (folder === "/") folder = undefined;
        setLoading(true);
       
        const token = await authorization.getToken();       
        try{
            const response = await fetch('FileViewer' + (folder && "?" + new URLSearchParams({
                folder: folder
            }) || ""), {
                headers: !token ? {}
                    : {'Authorization': `Bearer ${token}`}
            });
            
            if (response.status === 401) {
                throw new Error("Unauthorized");
            }
            
            const data = await response.json();
            setFolderItems(data);
            setLoading(false);
            
        }catch (error) {
            console.error("Error:", error);
            
            if(error.message === "Unauthorized")
                 navigate('/account/login', {state: {local: true}});
            
            setLoading(false);
        }
        
    }

    return (<>
        <div className="w-full">
            {/*<Actions/>*/}
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th></th>
                    <th></th>
                    <th scope="col" className="py-3 px-6">
                        Name
                    </th>
                    <th scope="col" className="py-3 px-6">
                        Size
                    </th>
                    {/*<th scope="col" className="py-3 px-6 hidden md:hidden">*/}
                    {/*    Created*/}
                    {/*</th>*/}
                    <th scope="col" className="py-3 px-6">
                        ...
                    </th>
                </tr>
                </thead>
                <tbody>
                
                {!loading && folderItems.map((item, index) =>{
                    if (index === 0 ){
                       return (<>
                            <Row key={index} item={item} index={index} folder={folderPath} setFolder={changeFolder}></Row>
                           {item.name !== '' && <FileUploader path={folderPath || ""} folderItems={folderItems}></FileUploader>}
                           </>)
                    }   
                    else{
                      return  <Row key={index} item={item} index={index} folder={folderPath} setFolder={changeFolder}></Row>
                    }
                })}
                {loading &&
                    <tr>
                        <td colSpan="5" className="py-4 px-6 text-center">
                            <div className="flex justify-center ">
                                <svg className="animate-spin -ml-1 mr-3 h-10 w-10 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        </td>
                    </tr>
                }
                </tbody>
            </table>

        </div>
    </>);
}
        

export default observer(Explorer);