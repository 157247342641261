import React, {Component} from 'react';
import './NavMenu.css';
import LoginMenu from "./api-authorization/LoginMenu";
import {NavItem, NavLink} from "reactstrap";
import {Link} from "react-router-dom";

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (           
            <>
                <header className="text-gray-600 body-font">
                    <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">

                        <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
                            <div className="rounded-circle w-10 h-10 text-white p-2 bg-indigo-500 rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Images</title>
                                    <path d="M432 112V96a48.14 48.14 0 00-48-48H64a48.14 48.14 0 00-48 48v256a48.14 48.14 0 0048 48h16" fill="none" stroke="currentColor"
                                          stroke-linejoin="round" stroke-width="32"/>
                                    <rect x="96" y="128" width="400" height="336" rx="45.99" ry="45.99" fill="none" stroke="currentColor" stroke-linejoin="round"
                                          stroke-width="32"/>
                                    <ellipse cx="372.92" cy="219.64" rx="30.77" ry="30.55" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32"/>
                                    <path d="M342.15 372.17L255 285.78a30.93 30.93 0 00-42.18-1.21L96 387.64M265.23 464l118.59-117.73a31 31 0 0141.46-1.87L496 402.91"
                                          fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                                </svg>                                
                            </div>
                            <span className="ml-3 text-xl">ImgManagr.com</span>
                        </a>
                        <nav
                            className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400 list-none flex flex-wrap items-center text-base justify-center gap-4">

                            <NavItem>
                                <NavLink tag={Link} className="text-dark text-decoration-none" to="/">Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark text-decoration-none" to="/pricing">Pricing</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark text-decoration-none" to="/my-storage">Storage</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} className="text-dark text-decoration-none" to="/faq">FAQ</NavLink>
                            </NavItem>
                        </nav>

                        <LoginMenu className="mt-4 lg:mt-0"/>
                    </div>
                </header>
            </>
            // <header className="bg-white border-b-2 border-gray-200">
            //     <nav className="container mx-auto flex items-center justify-between flex-wrap py-4">
            //         <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            //             <div className="text-sm lg:flex-grow">
            //                 <Link to="/" className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 font-bold">
            //                     PixPool.Web
            //                 </Link>
            //             </div>
            //             <div>
            //                 <button
            //                     onClick={this.toggleNavbar}
            //                     className="inline-block text-gray-800 focus:outline-none focus:text-gray-600"
            //                 >
            //                     <svg
            //                         className="h-6 w-6 fill-current"
            //                         viewBox="0 0 20 20"
            //                         xmlns="http://www.w3.org/2000/svg"
            //                     >
            //                         <title>Menu</title>
            //                         <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
            //                     </svg>
            //                 </button>
            //             </div>
            //         </div>
            //         <div
            //             className={`${
            //                 !this.state.collapsed ? "block" : "hidden"
            //             } w-full block flex-grow lg:flex lg:items-center lg:w-auto`}
            //         >
            //             <div className="text-sm lg:flex-grow">
            //                 <Link
            //                     to="/"
            //                     className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 font-bold hover:text-gray-600 mr-4"
            //                 >
            //                     Home
            //                 </Link>
            //                 <Link
            //                     to="/counter"
            //                     className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 font-bold hover:text-gray-600 mr-4"
            //                 >
            //                     Counter
            //                 </Link>
            //                 <Link
            //                     to="/fetch-data"
            //                     className="block mt-4 lg:inline-block lg:mt-0 text-gray-800 font-bold hover:text-gray-600 mr-4"
            //                 >
            //                     Fetch data
            //                 </Link>
            //             </div>
            //             <LoginMenu className="mt-4 lg:mt-0"/>
            //         </div>
            //     </nav>
            // </header>

        );
    }
}
