import {getRoot, types} from "mobx-state-tree";
import {accountBillingUrl, get} from "../api";
import {flow} from "mobx";

export const FileUploading = types
    .model({
        UploadingCount: 0,
    })
    .actions(self => {
        const decrementActiveUploads = () => {    
            self.UploadingCount -= 1;
        }
        
        const incrementActiveUploads = () => {
            self.UploadingCount += 1;
        }
        const canUpload = () => {
            
            return self.UploadingCount < 5;
        }
        
        return { incrementActiveUploads, decrementActiveUploads, canUpload };

} );