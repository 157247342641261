import {observer} from "mobx-react-lite";
import {useStore} from "../../models/RootStore";
import {useEffect, useRef, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import BasicGalleryLayout from "./Layouts/BasicLayout";
import 'react-gallery-carousel/dist/index.css';
import React from "react";
import MasonryGalleryLayout from "./Layouts/MasonryLayout";
import ImageComponent from "./ImageComponent";
import GalleryPreview from "./GalleryPreview";
import GallerySettings from "./GallerySettings";

const GalleryPage = () => {
    const { setPageTitle, gallery, cnd, iframe } = useStore();
    const {pathname} = useLocation();
    const [zoomLevel, setZoomLevel] = useState(4);

    const [openGallery, setOpenGallery] = useState(true);
    const [maximize, setMaximize] = useState("");
    const galleryRef = useRef(null);
    
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        const basePath = '/gallery';
        let path = "/"
        
        if (pathname.startsWith(basePath)) {
            path = pathname.substring(basePath.length) || '/';
            path = decodeURIComponent(path);
        }

        gallery.setPath(path);
        
        setPageTitle("Gallery - " + gallery.path);
        gallery.getGalleryItems().then(() => {
            if(gallery.galleryPreviewSettings.showSubGalleries){
                gallery.getSubGalleryItems();
            }           
        });
        
        setTimeout(() => {
            
        }, 1000);
        
    }, [pathname]);

    // Effect hook for observing size changes of the BasicGalleryLayout
    useEffect(() => {
        const observer = new ResizeObserver(() => {
            // Ensure that the component has been rendered and the ref is set
            if (galleryRef.current) {
                // Get the height of the BasicGalleryLayout component
                const height = galleryRef.current.offsetHeight;

                // Send the message to the iframe
                window.parent.postMessage({ height, id:searchParams.get("gid") }, "*");
            }
        });

        // Start observing the BasicGalleryLayout for size changes
        if (galleryRef.current) {
            observer.observe(galleryRef.current);
        }

        // Clean up function to stop observing for size changes when the component is unmounted
        return () => {
            if (galleryRef.current) {
                observer.unobserve(galleryRef.current);
            }
        };
    }, []); // Run this effect hook once when the component is mounted

    return (  <>
        { !iframe && 
            <GallerySettings gallerySettings={gallery.galleryPreviewSettings}></GallerySettings>
           
        }
        
        {
            gallery.galleryPreviewSettings.showDirectoryPreview && <GalleryPreview gallery={gallery}/>
        }
        
        
        
        <div className="w-full" ref={galleryRef} key={gallery.getGalleries.length}>           
            {gallery.getGalleries.map((item, index) => {
               return (
                   <div key={item.name} id={item.name}>
                            { gallery.galleryPreviewSettings.showGalleryTitleAndDescription  &&
                                <div className="flex flex-wrap w-full">
                                    <h1 className="w-full pr-1 text-2xl ">{item.name}</h1>               
                                    <div className="w-full pr-1 text-black-300">{item.description}</div>
                                </div>
                            }
                            {
                                gallery.galleryPreviewSettings.galleryStyle === "masonry" ?
                                    <MasonryGalleryLayout key={item.name} setting={gallery.galleryPreviewSettings} zoomLevel={gallery.galleryPreviewSettings.itemsPerRow} columnCount={gallery.galleryPreviewSettings.itemsPerRow} items={item.items} container={item.id} userId={gallery.userId}/>
                                    :
                                    <BasicGalleryLayout key={item.name} setting={gallery.galleryPreviewSettings} zoomLevel={gallery.galleryPreviewSettings.itemsPerRow} columnCount={gallery.galleryPreviewSettings.itemsPerRow} items={item.items} container={item.id} userId={gallery.userId}/>
                            }
                   </div>
               )
            })}
        </div>
    </>  );
}


export default observer(GalleryPage);