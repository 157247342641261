import React, {Component, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import AppRoutes from './AppRoutes';
import {Layout} from './components/Layout';
import { observer } from "mobx-react-lite"
import {useStore} from "./models/RootStore";

const App = () => {

    let store = useStore();
    
    useEffect(() => {
        if(store.name)
            document.title = store.name + " - ImgManagr.com"
        
    }, [store.name]);
    return (
        <Layout>
            {/* dynamically allocated padding for gallery image gap  */}
            
            <Routes>
                {AppRoutes.map((route, index) => {
                    const {element, requireAuth, ...rest} = route;
                    return <Route key={index} {...rest} element={element}/>;
                })}
            </Routes>
            <div className="p-1 p-2 p-3 p-4 p-5 hidden"></div>
        </Layout>);
}

export default observer(App)