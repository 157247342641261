import {observer} from "mobx-react-lite";
import {useStore} from "../../models/RootStore";
import {useEffect, useRef, useState} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import BasicGalleryLayout from "./Layouts/BasicLayout";
import 'react-gallery-carousel/dist/index.css';
import React from "react";
import MasonryGalleryLayout from "./Layouts/MasonryLayout";
import ImageComponent from "./ImageComponent";


const GalleryPreviewItem=({item,userId})=>{
    const [hover, setHover] = useState(false);
    
    return (
        <a className="p-4 md:w-[200px] cursor-pointer transition-all" 
           onMouseEnter={()=>setHover(true)} 
           onMouseLeave={()=>setHover(false)}
           onClick={ ()=>{ const element = document.getElementById(item.name); element.scrollIntoView(/*{ behavior: "smooth" }*/);}}
        >
            
            <div className={"h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden shadow-xl "+ (hover ? "bg-gray-200" : "")}>
                <ImageComponent
                    imgClass={"h-auto max-w-full shadow-lg "+ (hover? "contrast-50 zoom-2":"")}
                    userId={userId}
                    item={item.getImages[0]}
                    w={200}
                    h={200}
                    loadingH={"60"}
                />              
                <div className="pl-1 pt-1">
                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3">{item.name}<span
                        className="ml-1 inline-block py-1 px-2 rounded bg-purple-50 text-purple-500 text-xs font-medium tracking-widest">{item.items.length}</span></h1>
                    <p className="leading-relaxed mb-3">{item.description}</p>

                </div>
            </div>
        </a>
    )
}
const GalleryPreview = ({gallery}) => {
    return (  <>        
        <section className="text-gray-600 body-font w-full">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-wrap -m-4">
                    {gallery.getGalleries.map((item, index) => {
                       return (<>
                           {item && item.getImages.length > 0 &&
                                <GalleryPreviewItem key={"preview"+index} item={item} userId={gallery.userId}/>
                           }
                       </>)
                    })}
                </div>
            </div>
        </section>        
    </>  
    );
}


export default observer(GalleryPreview);