import {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {useStore} from "../models/RootStore";

const FaqsCard = (props) => {

    const answerElRef = useRef()
    const [state, setState] = useState(false)
    const [answerH, setAnswerH] = useState('0px')
    const {faqsList, idx} = props

    const handleOpenAnswer = () => {
        const answerElH = answerElRef.current.childNodes[0].offsetHeight
        setState(!state)
        setAnswerH(`${answerElH + 20}px`)
    }

    return (
        <section className="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 lg:px-8">
            <div className="space-y-3 text-center">
                <h1 className="text-3xl text-gray-800 font-semibold">
                    Frequently Asked Questions
                </h1>
                <p className="text-gray-600 max-w-lg mx-auto text-lg">
                    Answered all frequently asked questions, Still confused? feel free to contact us.
                </p>
            </div>
            <div className="mt-14 max-w-2xl mx-auto">
                {
                    faqsList.map((item, idx) => (
                        <FaqsCard
                            idx={idx}
                            faqsList={item}
                        />
                    ))
                }
            </div>
        </section>
    )
}


const Faq = () => {
    
    const { setPageTitle } = useStore();
    
    useEffect(() => {
        setPageTitle("FAQ");
    }, [])
    const faqsList = [
        {
            q: "How does your cloud-based image management platform work ?",
            a: "Our platform is hosted on the cloud, which means that you can access it from any device with an internet connection. " +
                "Simply sign up for an account, upload your images, and start organizing them into folders and collections. " +
                "Our platform also includes a range of features that help you tag, search, and share your images with ease."
        },
        {
            q: "Is your platform secure?",
            a: "Yes, we take security very seriously. Our platform uses advanced encryption to protect your images, and we also have strict access controls in place to ensure that only authorized users can view your images."
        },
        {
            q: "How much does your platform cost?",
            a: "We offer a range of pricing plans to suit different needs and budgets. You can choose from a basic plan that is free to use, or upgrade to one of our paid plans for additional features and storage."
        },
        {
            q: "Can I use your platform to store and share images with my team?",
            a: "Yes, our platform is designed to be used by teams. You can invite your colleagues to join your account, and then share images and folders with them. This makes it easy for your team to collaborate and stay organized, no matter where you are."
        },
        {
            q: "Can I use stored images in my homepage?",
            a: "Yes, you can use the images that you store on our cloud-based image management platform in your homepage. Once you've uploaded your images to our platform and organized them into folders or collections, you can simply use the URL for each image to embed it in your homepage. This can be done through the use of the HTML img tag or by using the built-in image embedding features of your website builder or content management system."
        },
        {
            q: "Is your platform fast?",
            a: "Yes, our platform is designed to be fast and responsive. We uses a content delivery network (CDN) to deliver images to users quickly and efficiently. A CDN is a network of servers that are distributed across the globe, and it is designed to deliver content, such as images, videos, and other digital assets, to users as quickly as possible."
        }
    ]

    return (
        <section className="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 lg:px-8">
            <div className="space-y-3 text-center">
                <h1 className="text-3xl text-gray-800 font-semibold">
                    Frequently Asked Questions
                </h1>
                <p className="text-gray-600 max-w-lg mx-auto text-lg">
                    Answered all frequently asked questions, Still confused? feel free to contact us.
                </p>
            </div>
            <div className="mt-14 gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-3">
                {
                    faqsList.map((item, idx) => (
                        <div
                            className="space-y-3 mt-5"
                            key={idx}
                        >
                            <h4 className="text-xl text-gray-700 font-medium">
                                {item.q}
                            </h4>
                            <p className="text-gray-500">
                                {item.a}
                            </p>
                        </div>
                    ))
                }
            </div>
        </section>
    )
}

export default observer(Faq);