import {types} from "mobx-state-tree";
import {flow} from "mobx-state-tree";
import {get, getUserInfoUrl} from "../api";

export const Authorization = types
    .model({
        token: types.maybe(types.string),
        user: types.maybe(types.frozen()),
        authorized: false
    })
    .views(self => ({
        get isLoggedIn() {
            return !!self.token;
        }
    }))
    .actions(self => {
       const isAuthorized = flow(function*() {
            let token = self.token;

            let AuthHeader = 'Bearer ' + token;

            try {
                let response = yield fetch('api/Auth/User', {
                    method: 'GET',
                    headers: {
                        "Authorization": AuthHeader
                    }
                });

                if (response.ok) {
                    self.user = yield response.json();
                    self.authorized = true;
                    return true;
                } else {
                    throw new Error('Authorization failed');
                }
            } catch (error) {
                console.error(error);
                return false;
            }
        });
       const getUser = flow(function*() {
           if (self.user) {
               return self.user;
           }

           self.user  = yield get(getUserInfoUrl);
           
           return self.user;
       })  
        const getToken= () =>{
            if (self.token) {
                return self.token;
            }

            const cookie = document.cookie.split(';').find(c => c.trim().startsWith('access_token='));
            if (cookie) {
                const token = cookie.split('=')[1];
                self.token = token;
                return token;
            }

            return null;
        };
        const setUser = (userData)=> {
            self.user = userData;
        };
        const setToken=(token, userData) =>{
            debugger;
            document.cookie = `access_token=${token};max-age=864000;path=/`;
            self.token = token;
            self.user = userData;
            self.authorized = true;
        };
        const logout=() =>{
            self.token = null;
            self.user = null;
            self.authorized = false;
            // Remove the access_token cookie
            document.cookie = 'access_token=;max-age=0;path=/';
        };
        return { isAuthorized, getToken, setUser, setToken, logout, getUser };
    });
