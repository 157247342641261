import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useStore} from "../../models/RootStore";
import {observer} from "mobx-react-lite";
import ImageComponent from "../Gallery/ImageComponent";
import {get, getFileUploadUrl} from "../../api";


const UploadFile = observer((props) => {
    const {authorization, fileUploading} = useStore();
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    const [speed, setSpeed] = useState("");
    const [uploaded, setUploaded] = useState(false);
    const [uploadStarted, setUploadStarted] = useState(false);
    const [uploadFinished, setUploadFinished] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [id, setId] = useState("");
    
    const  [uploadedPath, setUploadedPath] = useState("");
    useEffect(() => {
        setFile(props.file);       
    }, []);
    
    useEffect(() => {
        if (!file || uploadStarted || !fileUploading.canUpload()) return;

        setUploadStarted(true);
        fileUploading.incrementActiveUploads();
        
        handleUpload().then(res => {
            setUploadedPath(res.data);
            
            setUploadFinished(true); 
            setUploadError(false);            
            console.log(res);
            fileUploading.decrementActiveUploads();
        })
        .catch(err => {
            setUploadFinished(true);
            setUploadError(true);
            console.log(err);
            fileUploading.decrementActiveUploads()
        });
        setUploadStarted(true);
    }, [file, uploadStarted, fileUploading.UploadingCount])
    const handleRemove =()=>{
        props.onRemove();
    }
    const getSignedUrl = async (path, filename, contentType, length) => {        
        return  await get(getFileUploadUrl(path, filename, contentType, length));    
    };

    const handleUpload = async () => {

        let request = await getSignedUrl(props.path, file.name, file.type, file.size);
        let sessionUri = request.url;
        setId(request.id);
        
        let chunkSize = 25 * 1024 * 1024;
        let startByte = 0;
        let endByte = chunkSize;
        let res = {};
        
        let maxRetries = 3;
        let retries = 0;
        
        while (startByte < file.size) {
            endByte = Math.min(endByte, file.size);
            const blob = file.slice(startByte, endByte);
            try {                
                
                res = await axios.post(sessionUri, blob, {
                    headers: {                        
                        'Content-Type': file.type,
                        'Content-Range': `bytes ${startByte}-${endByte-1}/${file.size}`                        
                    },
                    onUploadProgress: (progressEvent) => {
                        const total = file.size;
                        const current = progressEvent.loaded;
                        
                        const chunkProgress = progressEvent.loaded / blob.size;
                        const progress = Math.round((startByte + chunkProgress * blob.size) / file.size * 100);
                        setProgress(progress);
    
                        let speed = progressEvent.rate; // in bytes
                        let speedKb = speed / 1024;
                        let speedMb = speedKb / 1024;
    
                        let speedText = "";
                        //show closest unit 
                        if (speedMb > 1) {
                            speedText = speedMb.toFixed(2) + " Mb/s";
                        } else if (speedKb > 1) {
                            speedText = (speedKb.toFixed(2) + " Kb/s");
                        } else {
                            speedText = setSpeed((speed || "0") + " b/s");
                            return;
                        }
                        if(speedText !== speed || speedText !== "0 b/s")
                            setSpeed(speedText);
                    },
                    
                });
    
                startByte = endByte;
                endByte = startByte + chunkSize;
            }
            catch (err) {
                console.log("Error uploading chunk", err);
                
                if(retries > maxRetries) return Promise.reject(err);
                retries++;
            }
        }
        return Promise.resolve(res);     
    };
    
    const handleUpload2 = async () => {
       
        let uploadUrl = await getSignedUrl(props.path, file.name, file.type);
       
        let token = authorization.getToken();

        const formData = new FormData();

        formData.append("files", file);

        let startTime = new Date().getTime();
        try {
            let res = axios.post(uploadUrl,
                formData,{
                    headers: {"Content-Type": "multipart/form-data" },
                    //timeout: 60 * 1000, // 1 min
                    onUploadProgress: (progressEvent) => {
                        const total = file.size;
                        const current = progressEvent.loaded;

                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setProgress(progress);

                        let speed = progressEvent.rate; // in bytes
                        let speedKb = speed / 1024;
                        let speedMb = speedKb / 1024;

                        let speedText = "";
                        //show closest unit 
                        if (speedMb > 1) {
                            speedText = speedMb.toFixed(2) + " Mb/s";
                        } else if (speedKb > 1) {
                            speedText = (speedKb.toFixed(2) + " Kb/s");
                        } else {
                            speedText = setSpeed((speed || "0") + " b/s");
                            return;
                        }

                        setSpeed(speedText);
                    }
                });   
            return Promise.resolve(res);
        } catch (err) {
           
            return Promise.reject(err);
        }       
    };
    
    const handleUploadInfo = () => {
        
        if(uploadError) return (
            <div className="text-warning">Upload Error</div>)
        
        if(uploadFinished) return (
            <div className="text-success">Upload Successfull</div>)
        
        if(uploadStarted) 
            return (<div> {speed} </div>)
        
        return (<>---</>)
            
    }
    
    return (<>
            {file &&                
            <tr className={"bg-white border-b dark:bg-gray-800 dark:border-gray-700 transition-all duration-300 easy "}
                key={file.name}>
                <td className="pl-2 w-[60px]">
                    {/*{file.type.startsWith("image/") && <img loading="lazy" src={URL.createObjectURL(file)} alt={file.name} className="min-w-[60px] object-cover rounded-lg"/>}*/}
                    {uploadFinished && authorization.user && authorization.user.userId && uploadedPath && file.type.startsWith("image/") && <ImageComponent
                        imgClass={" max-h-[91px] max-w-[91px]"}
                        userId={authorization.user.userId}
                        item={{nameFull:uploadedPath, id:id}}
                        w={100}
                        h={100}
                        loadingH={92}
                        q={80}
                        ></ImageComponent>}
                    {!file.type.startsWith("image/") && <div>{file.type}</div>}
                </td>
                <td className="pl-2">{handleUploadInfo()}</td>
                <td scope="row" className="py-4 pr-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {file.name}
                </td>
                <td className="py-4 px-6">{(file.size / 1024 / 1024).toFixed(2)} MB</td>

                <td className="py-4 px-6">
                    <button className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110" onClick={() => handleRemove(file)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512"><title>Trash</title>
                            <path d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none" stroke="currentColor" strokeLinecap="round"
                                  strokeLinejoin="round" strokeWidth="32"/>
                            <path stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M80 112h352"/>
                            <path d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224" fill="none"
                                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/>
                        </svg>
                    </button>
                </td>
            </tr>
            }
            {progress !== undefined && !uploadFinished &&
                <tr>
                    <td colSpan={6} className="w-full bg-gray-50 h-0.5 dark:bg-gray-700">
                        <div className="bg-blue-600 h-2.5" style={{width: progress + "%"}}></div>
                    </td>
                </tr>
            }
        </>
    );
});
function FileUploadForm(props) {
    const [files, setFiles] = useState([]);
    const [isDraggingOver, setIsDraggingOver] = useState(false);
    const fileInputRef = useRef(null);
    const [skipDuplicate, setSkipDuplicate] = useState(true);
    
    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const fileList = event.dataTransfer.files;
        const filesArray = [...fileList];
        setFiles((prevFiles) => [...prevFiles, ...filesArray]);
        setIsDraggingOver(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDraggingOver(true);
       
    };
    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDraggingOver(true);
    };
    
    const handleRemove = (fileToRemove) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    };
    function handleDragLeave(e) {
        e.preventDefault();
        setIsDraggingOver(false);
    }
    const handleFileUpload = async (event) => {
        setFiles((prevFiles) => [...prevFiles, ...event.target.files]);        
    };
    return (<>

            
        {files.map((file, index) =>{
            if(skipDuplicate && props.folderItems && props.folderItems.find(x => x.name === file.name)) 
                return (<></>)            
            return <UploadFile key={"upload"+index} file={file} path={props.path} onRemove={handleRemove} />}
        )}
        <tr onDrop={handleDrop} onDragOver={handleDragOver}  onDragLeave={handleDragLeave} onDragEnter={handleDragEnter}>
            <td colSpan="6" className={"py-4 px-6 text-center border-2 border-dashed "+ (isDraggingOver ? " bg-green-100" : " ")} >

                <div className="file-upload-form" onDrop={handleDrop} onDragOver={handleDragOver}  onDragLeave={handleDragLeave} >
                    <div className="dropzone" onClick={() => fileInputRef.current.click()}>
                        <div className="dropzone-icon">
                            <i className="fa fa-cloud-upload"></i>
                        </div>
                        <div className="dropzone-text text-2xl">
                                      Drag and drop files here or click to upload
                        </div>
                        <div className="flex items-center z-500" >
                            <input 
                                type="checkbox"
                                id="checkbox"
                                checked={skipDuplicate}
                                onChange={(e) =>{setSkipDuplicate(e.target.checked);}}
                                onClick={(e) => e.stopPropagation()}
                                className="form-checkbox h-5 w-5 text-blue-600"
                            />
                            <label htmlFor="checkbox" className="ml-2 text-gray-700" onClick={(e) => e.stopPropagation()}>
                                Skip uploading if file already exists
                            </label>
                        </div>
                    </div>
                </div>
                <input type="file" ref={fileInputRef} multiple onChange={handleFileUpload}  className="hidden"/>

            </td>
        </tr>
    </>);
}
export default observer(FileUploadForm);
