import React from "react";
import {observer} from "mobx-react-lite";


const GallerySettings = ({gallerySettings, galleryType}) => {
    
    return (  <>
                <div className="bg-white rounded w-full p-4 shadow">
                    <div className="flex">
                        <div className="w-2/3 mb-10">
                            <h1 className="font-semibold">
                                Settings
                            </h1>
                        </div>                        
                    </div>
                    
                    <div className="flex">
                        <div className="w-1/2 flex-col m-2">
                            <div>
                                <label htmlFor="Gallery style" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Gallery style</label>
                                <select id="country" name="galleryStyle"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required=""
                                        value={gallerySettings.galleryStyle}
                                        onChange={(e) => gallerySettings.setGalleryStyle(e.target.value)}
                                >
                                    <option value="basic">Basic</option>
                                    <option value="masonry">Masonry (preview)</option>
                                    
                                </select>
                            </div>
                        </div>
                        <div className="w-1/2 flex-col m-2">
                           
                        </div>                        
                    </div>
                                       
                    <div className="border border-gray-200 dark:border-gray-700 pt-4">
                        <b className="m-2">Items per row based on screen size</b>
                    
                        <div className="flex">
                            { ["Small","Medium","Large", "XLarge"]
                                .map((size) => {
                                return <div className="w-1/3 flex-col m-2">
                                    <div>
                                        <label htmlFor={size + " screen"} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{size} screen</label>
                                        <select name={size + " screen"}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                required=""
                                                value={gallerySettings.itemsPerRow(size.toLowerCase())} onChange={(e) => gallerySettings.setScreenItemsPerRow(e.target.value, size.toLowerCase())}>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                            <option value="4">Four</option>
                                            <option value="5">Five</option>
                                            <option value="6">Six</option>
                                        </select>
                                    </div>
                                </div>
    
                            })        
                        }
                            
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-1/3 flex-col m-2">
                            <div>
                                <label htmlFor="rounded corners" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Rounded corners</label>
                                <select id="country" name="roundedCorners"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required=""
                                        value={gallerySettings.roundCorners}
                                        onChange={(e) => gallerySettings.setRoundCorners(e.target.value)}
                                >
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-1/3 flex-col m-2">
                            <div>
                                <label htmlFor="image gap" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Image gap</label>
                                <select id="country" name="imageGap"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required=""
                                        value={gallerySettings.gap}
                                        onChange={(e) => gallerySettings.setImageGap(e.target.value)}
                                >
                                    <option value="0">None</option>
                                    <option value="1">Small</option>
                                    <option value="2">Medium</option>
                                    <option value="3">Large</option>
                                    <option value="4">Extra large</option>
                                    <option value="5">Extra extra large</option>
                                </select>
                            </div>
                        </div>
                               
                        <div className="w-1/3 flex-col m-2">
                            <div>
                                <label htmlFor="showTitleAndDescription" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Show title and description</label>
                                <select id="country" name="showTitleAndDescription"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required=""
                                        value={gallerySettings.showGalleryTitleAndDescription}
                                        onChange={(e) => gallerySettings.setShowGalleryTitleAndDescription(e.target.value)}
                                >
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </div>
                        </div>

                    </div>
                    <div className="flex mt-2">
                        <div className="w-1/3 flex-col m-2">
                            <div>
                                <label htmlFor="gallery preview" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Show gallery previews</label>
                                <select id="showDirectoryPreview" name="showDirectoryPreview"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required=""
                                        value={gallerySettings.showDirectoryPreview}
                                        onChange={(e) => gallerySettings.setShowDirectoryPreview(e.target.value)}
                                >
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-1/3 flex-col m-2">
                            <div>
                                <label htmlFor="imagesPerRow" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Show sub directories</label>
                                <select id="country" name="country"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required=""
                                        value={gallerySettings.showSubGalleries}
                                        onChange={(e) => gallerySettings.setShowSubGalleries(e.target.value)}
                                >
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </div>
                        </div>
                        <div className="w-1/3 flex-col m-2">
                            <div>
                                <label htmlFor="showVideos" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Show videos</label>
                                <select id="country" name="country"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        required=""
                                        value={gallerySettings.showVideos}
                                        onChange={(e) => gallerySettings.setShowVideos(e.target.value)}
                                >
                                    <option value="true">True</option>
                                    <option value="false">False</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="flex pt-2 justify-content-end">
                {/*        share button */}
                        <div className="w-1/6 flex-col">
                                <button className="w-full block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500">
                                   Share
                                </button>                            
                        </div>  
                    </div>
                </div>
        </>
    );
}

export default observer(GallerySettings);