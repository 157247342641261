import {useStore} from "../../../models/RootStore";
import ImageComponent from "../ImageComponent";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {Preview} from "../Preview";
import {W} from "tw-elements/dist/src/js/mdb/util/keycodes";
import {useWindowSize} from "../../../hooks/windowsSize";
import useScreenSize from "../../../hooks/useScreenSize";

const MasonryGalleryLayout = (props) => {
    const { cnd } = useStore();
    const { items, userId } = props;
    const [imageHeights, setImageHeights] = useState([]);
    const [galleryItems, setGalleryItems] = useState([]);
    const [columnCount, setColumnCount] = useState(4);
    
    // image size
    const [clientWidth, setClientWidth] = useState(null);
    const [width, setWidth] = useState((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth));
    
    //preview
    const [openGallery, setOpenGallery] = useState(false);
    const [openGalleryIx, setOpenGalleryIx] = useState(0);
    const [maximize, setMaximize] = useState("---");
    
    const [windowsWidth, windowsHeight] = useWindowSize()
    const screenSize = useScreenSize();
    
    useEffect(() => {
        if (items && items.length > 0) {
            setGalleryItems(items.filter((item,index)=>index > 0 &&
                item.isFolder === false &&
                item.contentType.indexOf('image') > -1));
        }
        
    }, [items]);

    useEffect(() => {
        setColumnCount(gridCols(screenSize));
        setClientWidth((width  / gridCols(screenSize)) - 10);
    }, [screenSize]);
    
    const q = 80;
    
    const gridCols = (screenSize)=> {

        switch (screenSize) {
            case "sm":
            case "xs":
                return props.setting.itemsPerRowSmall;
            case "md":
                return props.setting.itemsPerRowMedium;
            case "lg":
                return props.setting.itemsPerRowLarge;
            case "xl":
            case "2xl":
                return props.setting.itemsPerRowXLarge;
            default:
                return props.setting.itemsPerRowSmall;
        }
    }
    const getColumnItems = (columnIndex, totalColumns) => {
        if(!galleryItems) return [];

        return galleryItems.filter((_, index) => index % totalColumns === columnIndex);
    };
    const getItemsInColumn = (columnIndex, totalColumns) => {
        if(!items) return [];

        let columnItems = getColumnItems(columnIndex, totalColumns);
        if (columnItems.length === 0) return [];
        return columnItems;
    };
    const elRef = useRef();

    useEffect(() => {
        console.log("elRef?.current?.clientWidth",elRef?.current?.clientWidth);
        if (!elRef?.current?.clientWidth) {
            return;
        }
        
        setWidth(elRef?.current?.clientWidth);
    }, [elRef?.current?.clientWidth]);
    
    const size = ()=>{
        //match predefined size 
        if (clientWidth < 100) return 100;
        if (clientWidth <= 200) return 200;
        if (clientWidth <= 300) return 300;
        if (clientWidth <= 400) return 400;
        if (clientWidth <= 500) return 500;
        if (clientWidth <= 600) return 600;
        if (clientWidth <= 900) return 900;
        if (clientWidth <= 1200) return 1200;
        if (clientWidth <= 1600) return 1600;
        else return 1900;
    };
    const w = size();
    
    useEffect(() => {
        //setZoomLevel(props.zoomLevel);
        setClientWidth((width  / props.zoomLevel) - 10);
    }, [props.zoomLevel,props.gallery]);
    
    return (
        <>
            <Preview key={"preview"+openGallery} openGallery={openGallery} maximize={maximize} galleryItems={items} index={openGalleryIx} userId={userId} />
            <div className="container mx-auto py-2 lg:pt-12">
                <div key={"grid"+columnCount} ref={elRef} className={`grid grid-cols-${columnCount}`}>
                    {[...Array(columnCount)].map((_, columnIndex) => (
                        <div key={'column-' + columnIndex} className="">
                            {getItemsInColumn(columnIndex, columnCount).map((item, index) => {
                               
                                    return (
                                        <div key={'img-' + index} className={"cursor-pointer hover:contrast-50 "+ (props.setting.gap? "p-"+props.setting.gap : "")}
                                             onClick={()=>{setOpenGalleryIx((columnIndex+1)*(index+1)-1); setOpenGallery(true); setMaximize(Date.now().toString()); }}>
                                            <ImageComponent
                                                imgClass={"h-auto max-w-full shadow-lg ease-linear transition-all duration-150" + (props.setting.roundCorners ? " rounded-lg" : "")}
                                                userId={userId}
                                                item={item}
                                                w={w}
                                                keepAspectRatio={true}
                                                q={q}
                                                loadingH="400"                                                
                                            />
                                        </div>
                                    );
                                
                            }) 
                            }
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
export default observer(MasonryGalleryLayout)