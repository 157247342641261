import {Instance,types} from "mobx-state-tree";
import {createContext, useContext} from "react";
import {Authorization} from "./AuthorizationStore";
import {AccountStore} from "./AccountStore";
import {GalleryStore} from "./GalleryStore";
import {FileUploading} from "./FileUpload";
import {DirectoryStore} from "./DirectoryStore";

const RootModel = types.model({
    name: types.string,
    cnd: types.string,
    authorization: Authorization,
    account: AccountStore,
    gallery: GalleryStore,
    fileUploading: FileUploading,
    directory:DirectoryStore,
    iframe: types.boolean    
})
.actions(self => {
    const setPageTitle = (name) => {
        self.name = name;
    };
    const setIframe = (value) => {
        self.iframe = value;
    }
    
    return { setPageTitle, setIframe };
});


export const CreateRootStore = () => RootModel.create({
    name: "",
    cnd:"https://cnd.imgmanagr.com/api/image/",
    iframe: false, 
    authorization: Authorization.create({}),
    account: AccountStore.create({}),
    gallery: GalleryStore.create({ galleryPreviewSettings: {itemsPerRow: 4}}),
    fileUploading: FileUploading.create({}),
    directory: DirectoryStore.create({}),
    //page: {  },
});


export const Provider = createContext(RootModel);
export const useStore = () => {
    return useContext(Provider);
};