import {getRoot, types} from "mobx-state-tree";
import {post, updateDirectoryUrl} from "../api";
import {flow} from "mobx";

export const DirectoryStore = types
    .model({
        loading: false,
    })
    .actions(self => {       
        const updateDirectoryName = flow(function*(storagePath, newDirectoryName){
            self.loading = true;            
            let result = yield post(updateDirectoryUrl, {

                storagePath,
                newDirectoryName
            });
            
            self.loading = false;
        });
        return { updateDirectoryName };
    } );