import {observer} from "mobx-react-lite";
import {useStore} from "../../models/RootStore";
import React, {useEffect, useState} from "react";
import Carousel from "react-gallery-carousel";

export const Preview = observer((props) => {
    const { cnd } = useStore();

    const carusel = React.createRef();

    const [openGallery, setOpenGallery] = useState( props.openGallery);
    useEffect(() => {

        if(props.openGallery === true && carusel.current){
            carusel.current.maximize();
            
            setOpenGallery(true);
        }
    }, [props.maximize, props.openGallery]);
    const carouselImages2 = ()=>{
        const images = [];
        if(props.galleryItems){
            let scrWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth; 
            props.galleryItems.forEach(item => {
                if (item.contentType && item.contentType.indexOf('image') > -1){
                    images.push({
                        src: cnd+props.userId+"/transform/image?file="+encodeURI(item.nameFull)+`&width=${scrWidth}&format=webp&quality=80&format=webp&keepAspectRatio=true`,
                        thumbnail: cnd+props.userId+"/transform/image?file="+encodeURI(item.nameFull)+`&width=400&height=400&format=webp&quality=80`,
                        srcset: cnd+props.userId+"/transform/image?file="+encodeURI(item.nameFull)+`&width=${scrWidth}&format=webp&quality=80&format=webp&keepAspectRatio=true`,                            
                    })
                }
            });
        }
        return images;
    }

    return (<>
        {
            openGallery &&
            <Carousel ref={carusel} id={Date.now()} index={props.index} images={carouselImages2()} isMaximized={openGallery} style={{display:"none"}} />
        }
    </>)
})  