import {flow, types} from "mobx-state-tree";
import {get, getUserGalleryUrl} from "../api";

export const GalleryItem = types
    .model({
        isFolder: types.maybe(types.boolean),
        
        //timeCreated: types.maybe(types.string) ?? "",
        size: types.maybe(types.number),
        name: types.maybe(types.string),
        contentType: types.maybe(types.string),
        // eTag: types.maybe(types.string),s
        id: types.maybe(types.string),
        nameFull: types.maybe(types.string),
        // hasThumbnail: types.maybe(types.boolean),
        // thumbnailName: types.maybe(types.string),
        //metadata: types.maybe(types.string),
    });

export const Gallery = types
    .model({
        name: types.maybe(types.string),
        description: types.maybe(types.string),
        items: types.array(GalleryItem),
    })
    .views(self => ({
        get getImages() {
            if (!self && !self.items) return [];
            
            console.log("getImages", self.items.filter(x=>x.isFolder === false && x.contentType && x.contentType.startsWith("image")));
            return  self.items.filter(x=>x.isFolder === false && x.contentType && x.contentType.startsWith("image"));
        }
    }))       
;

export const GalleryPreviewSettings = types
    .model({
        showSubGalleries: types.optional(types.boolean, true),
        showDirectoryPreview: types.optional(types.boolean, false),
        galleryStyle: types.optional(types.string, "basic"),
        roundCorners: types.optional(types.boolean, true),
        gap: types.optional(types.number, 2),
        showGalleryTitleAndDescription: types.optional(types.boolean, true),
        itemsPerRowSmall:  2,
        itemsPerRowMedium: 3,
        itemsPerRowLarge: 4,
        itemsPerRowXLarge: 5,
        showVideos: types.optional(types.boolean, true),
    })
    .views(self => {

            const itemsPerRow = (size) => {
                switch (size) {
                    case "small":
                        return self.itemsPerRowSmall;
                    case "medium":
                        return self.itemsPerRowMedium;
                    case "large":
                        return self.itemsPerRowLarge;
                    case "xlarge":
                        return self.itemsPerRowXLarge;
                    default:
                        return self.itemsPerRowSmall;
                }
            }
        
            return {itemsPerRow}
        }
    )
    
    .actions(self => {
        const setItemsPerRow = (value) => {
            self.itemsPerRow = parseInt(value);
        }
        const setShowSubGalleries = (value) => {
            self.showSubGalleries = value.toLowerCase() === "true";
        }
        const setShowDirectoryPreview = (value) => {
            self.showDirectoryPreview =  value.toLowerCase() === "true";
        }
        const setGalleryStyle = (value) => {
            self.galleryStyle = value;
        }
        const setRoundCorners = (value) => {
            self.roundCorners = value.toLowerCase() === "true";
        }
        const setImageGap = (value) => {
            self.gap = parseInt(value);
        }
        const setShowGalleryTitleAndDescription = (value) => {
            self.showGalleryTitleAndDescription = value.toLowerCase() === "true";
        }
        const setShowVideos = (value) => {
            self.showVideos = value.toLowerCase() === "true";
        }
        const setScreenItemsPerRow = (value, size) => {
            switch (size) {
                case "small":
                    self.itemsPerRowSmall = parseInt(value);
                    break;
                case "medium":
                    self.itemsPerRowMedium = parseInt(value);
                    break;
                case "large":
                    self.itemsPerRowLarge = parseInt(value);
                    break;
                case "xlarge":
                    self.itemsPerRowXLarge = parseInt(value);
                    break;
                default:
                    break;
            }
        }
        return {setItemsPerRow, setShowSubGalleries, setShowDirectoryPreview, setGalleryStyle, setRoundCorners, setImageGap, setShowGalleryTitleAndDescription, setScreenItemsPerRow}
    })
export const GalleryStore = types
    .model({
        path: types.maybe(types.string),
        userId: types.maybe(types.string),
        galleries: types.array(Gallery),  
        galleryPreviewSettings: GalleryPreviewSettings
    })   
    .views(self => ({
        get getGalleries() {
            if (!self && !self.galleries) return [];
            
            return self.galleryPreviewSettings.showSubGalleries === true ?
                self.galleries : [self.galleries[0]];
                    
        }
    }))        
    .actions(self => {
        const setPath = (value) => {
            self.userId = value.split("/")[1];
            self.path = value.replace("/"+self.userId,"");
        }
        const getGalleryItems = flow(function*(){
            let url = getUserGalleryUrl.replace("{userId}", self.userId).replace("{galleryId}", self.path);
            let gallery = Gallery.create(yield get(url));
            self.galleries = [gallery];
            
            document.title =  gallery.name + " - " + "Image Gallery  - provided by ImgManagr.com";
            
            let descMeta=document.querySelector("meta[name='description']")
            descMeta.setAttribute("content", gallery.description)
            
            gallery.items.filter(x=>x.isFolder === false)
                .sort((a,b)=>{return 0.5 - Math.random()}).slice(0,1).forEach(x=> {
                let image = `/api/image/${self.userId}/transform/user?file=${x.nameFull}&width=1200&height=630&quality=80&format=jpg`;
               
                let meta = document.querySelector("meta[property='og:image']");
                meta.setAttribute("content", image);
                
            });
        });
        const getSubGalleryItems = flow(function*(){
            
            let first = self.galleries[0];
            
            if (first && first.items && first.items.length > 0) {
                console.log("first", first)
                for (let i = 0; i < first.items.length; i++) {
                    let currentItem = first.items[i];

                    console.log("currentItem", currentItem)
                    if (currentItem && currentItem.isFolder === true) {
                        console.log("currentItem", currentItem)
                        let url = getUserGalleryUrl.replace("{userId}", self.userId).replace("{galleryId}", currentItem.id);
                        
                        self.galleries = [...self.galleries, Gallery.create(yield get(url))];
                    }
                }
            }
            
            
        });
        return { setPath, getGalleryItems, getSubGalleryItems };

    });