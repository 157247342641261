import React, {useEffect} from "react";
import axios from "axios";
import {useStore} from "../../../models/RootStore";
import {useNavigate} from "react-router-dom";

{/* public, private, password-protected   */
}

function CreateFolderAccessRights(props) {
    const [access, setAccess] = React.useState("public");
    const [galleryName, setGalleryName] = React.useState("");
    const [galleryPassword, setGalleryPassword] = React.useState("");
   
    useEffect(() => {
        props.onChange({access, galleryName, galleryPassword});
    }, [access, galleryName, galleryPassword]);
    
    return (
        <div className="m-5">
            <div className="mb-3 xl:w-96">
                <label htmlFor="gallery-name" className="form-label inline-block mb-2 text-gray-700">Gallery name</label>
                <input
                    type="text"
                    className="
                                form-control
                                block
                                w-full
                                px-3
                                py-1.5
                                text-base
                                font-normal
                                text-gray-700
                                bg-white bg-clip-padding
                                border border-solid border-gray-300
                                rounded
                                transition
                                ease-in-out
                                m-0
                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                              "
                    id="gallery-name"
                    placeholder="Gallery name"
                    value={galleryName}
                    onChange={e => setGalleryName(e.target.value)}
                />
            </div>

            <h3>Gallery Access Rights</h3>
            <div className="flex justify-center">
                <div>
                    {/*  public   */}
                    <div className="form-check">
                        <input
                            className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="radio" name="access" id="public" checked={access === "public"} onChange={a => setAccess("public")}/>
                        <label className="form-check-label inline-block text-gray-800" htmlFor="public">
                            Public
                        </label>
                    </div>

                    {/*  private   */}
                    <div className="form-check">
                        <input
                            className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="radio" name="access" id="private" checked={access === "private"} onChange={a => setAccess("private")}/>
                        <label className="form-check-label inline-block text-gray-800" htmlFor="private">
                            Private
                        </label>
                    </div>
                    {/*  password-protected   */}
                    <div className="form-check">
                        <input
                            className="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="radio" name="access" id="password-protected" checked={access === "password-protected"} onChange={a => setAccess("password-protected")}/>
                        <label className="form-check-label inline-block text-gray-800" htmlFor="password-protected">
                            Password protected
                        </label>
                        <input type="text" id="gallery-password" name="gallery-password"/>
                    </div>
                    {/*  when password-protected  show enter password  */}
                    <div className="ml-5">
                        {access === "password-protected" && 
                            <div className="ml-5">
                                <label htmlFor="gallery-password" className="form-label inline-block mb-2 text-gray-700">Password</label>
                                <input
                                    type="text"
                                    className="
                                                form-control
                                                block
                                                w-full
                                                px-3
                                                py-1.5
                                                text-base
                                                font-normal
                                                text-gray-700
                                                bg-white bg-clip-padding
                                                border border-solid border-gray-300
                                                rounded
                                                transition
                                                ease-in-out
                                                m-0
                                                focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                                                "
                                    id="gallery-password"
                                    placeholder="Password"
                                    value={galleryPassword}
                                    onChange={e => setGalleryPassword(e.target.value)}
                                />
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function CreateFolderAction(props) {

    const {authorization} = useStore();
    const [showModal, setShowModal] = React.useState(false);
    const [values, setValues] = React.useState({}); 

    const navigate = useNavigate();
    
    const createDirectory  = () => {
        let res = axios.post('api/Directory/Create',
            {   
                directoryName: values.galleryName, 
                storagePath: props.folder,
                isPublic: values.access === "public",
                isPasswordProtected: values.access === "password-protected",
                password: values.password
            },{
                headers: {"Authorization": "Bearer " + authorization.getToken(), "Content-Type": "application/json" },
            });
        
        res.then((response) => {
            console.log(response);
            setShowModal(false);
            let parentDir = props.folder ?? "";
            navigate("/my-storage"+parentDir+"/"+values.galleryName, { replace: true });
        }).catch((error) => {
            console.log(error);

            setShowModal(false);
        });
    }
    useEffect(() => {
        authorization.getUser();
        
    },[authorization.user])
    return (
        <>
            <button
                className="bg-indigo-600 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >
                New Folder
            </button>
            {authorization.user && props.id &&
            <a
                className="bg-indigo-600 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 align-content-center"
                type="button"
               href={"/gallery/"+authorization.user.userId +"/"+ props.id}
            >
                Preview
            </a>
            }
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-3xl font-semibold">
                                        Create a Directory
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                      ×
                                    </span>
                                    </button>
                                </div>
                                <CreateFolderAccessRights onChange={a=>setValues(a)}></CreateFolderAccessRights>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => createDirectory()}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}