import { useState, useEffect } from 'react';

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState('');

    useEffect(() => {
        const match = {
            sm: window.matchMedia('(min-width: 640px)'),
            md: window.matchMedia('(min-width: 768px)'),
            lg: window.matchMedia('(min-width: 1024px)'),
            xl: window.matchMedia('(min-width: 1280px)'),
            '2xl': window.matchMedia('(min-width: 1536px)'),
        };

        const handleChange = () => {
            switch(true){
                case match['2xl'].matches:
                    setScreenSize('2xl');
                    break;
                case match['xl'].matches:
                    setScreenSize('xl');
                    break;
                case match['lg'].matches:
                    setScreenSize('lg');
                    break;
                case match['md'].matches:
                    setScreenSize('md');
                    break;
                case match['sm'].matches:
                    setScreenSize('sm');
                    break;
                default:
                    setScreenSize('xs');
            }
        };

        handleChange();
        for (let size in match) {
            match[size].addListener(handleChange);
        }

        return () => {
            for (let size in match) {
                match[size].removeListener(handleChange);
            }
        };
    }, []);

    return screenSize;
};

export default useScreenSize;
