import Explorer from "./components/Explorer/Main";
import Home from "./components/Home";
import Faq from "./components/Faq";
import LoginPage from "./components/api-authorization/LoginPage";
import RegisterPage from "./components/api-authorization/RegisterPage";
import Pricing from "./components/Pricing";
import AccountPage from "./components/api-authorization/AccountPage";
import GalleryPage from "./components/Gallery/Gallery";


const AppRoutes = [
    {
        index: true,
        element: <Home/>
    },
    {
        path: '/pricing',
        requireAuth: false,
        element: <Pricing/>
    },
    {
        path: '/my-storage',
        requireAuth: true,
        element: <Explorer/>
    },
    {
        path: '/my-storage/*',
        requireAuth: true,
        element: <Explorer/>
    },    
    {
        path: '/faq',
        requireAuth: true,
        element: <Faq/>
    },
    {
        path: '/account/login',
        requireAuth: false,
        element: <LoginPage/>
    },
    {
        path: '/account/register',
        requireAuth: false,
        element: <RegisterPage />
    },
    {
        path: '/account',
        requireAuth: true,
        element: <AccountPage/>
    },
    {
        path: '/gallery/*',
        requireAuth: true,
        element: <GalleryPage/>
    }
];

export default AppRoutes;
