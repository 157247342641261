import {observer} from "mobx-react-lite";
import {useStore} from "../../../models/RootStore";


import React, {useEffect, useRef, useState} from 'react';
import {Preview} from "../Preview";
import ImageComponent from "../ImageComponent";
import useScreenSize from "../../../hooks/useScreenSize";
import VideoPlayer from "../../VideoPlayer/VideoPlayer";

const BasicGalleryLayout = (props) => {
    const { cnd } = useStore();
    const { items, userId } = props;
    const [zoomLevel, setZoomLevel] = useState(3);

    //preview    
    const [openGallery, setOpenGallery] = useState(false);
    const [openGalleryIx, setOpenGalleryIx] = useState(0);
    const [maximize, setMaximize] = useState("---");
    
    // image size
    const [clientWidth, setClientWidth] = useState(null);
    const [width, setWidth] = useState((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth));
    
    const screenSize = useScreenSize();
    
    const elRef = useRef();

    useEffect(() => {
        if (!elRef?.current?.clientWidth) {
            return;
        }
        setWidth(elRef?.current?.clientWidth);
    }, [elRef?.current?.clientWidth]);

    
    useEffect(() => {
        setZoomLevel(gridCols(screenSize));
        setClientWidth((width  / gridCols(screenSize)) - 10);
    }, [props.gallery,screenSize]);
    const size = ()=>{                
        //match predefined size 
        if (clientWidth < 100) return 100;
        if (clientWidth <= 200) return 200;
        if (clientWidth <= 300) return 300;
        if (clientWidth <= 400) return 400;
        if (clientWidth <= 500) return 500;
        if (clientWidth <= 600) return 600;
        if (clientWidth <= 900) return 900;
        if (clientWidth <= 1200) return 1200;
        if (clientWidth <= 1600) return 1600;
        else return 1900;        
    }; 
    const q = 100;
        
    const gridCols = (screenSize)=> {
        
        switch (screenSize) {
            case "sm":
            case "xs":
                return props.setting.itemsPerRowSmall;            
            case "md":
                return props.setting.itemsPerRowMedium;
            case "lg":
                return props.setting.itemsPerRowLarge;
            case "xl":
            case "2xl":               
                return props.setting.itemsPerRowXLarge;
            default:
                return props.setting.itemsPerRowSmall;
        }
    }
    return (
        <>
            {/*<button className={"px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"} */}
            {/*        onClick={()=>{setOpenGallery(true); setMaximize(Date.now().toString())}}>Preview</button>*/}
            <Preview key={"preview"+openGallery} openGallery={openGallery} maximize={maximize} galleryItems={items} index={openGalleryIx} userId={userId} />
            <div className="container mx-auto lg:pt-12">
                <div className="d-none grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 sm:grid-cols-1 sm:grid-cols-2 sm:grid-cols-3 sm:grid-cols-4 sm:grid-cols-5 md:grid-cols-1 md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 md:grid-cols-5 lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4 lg:grid-cols-5 xl:grid-cols-1 xl:grid-cols-2 xl:grid-cols-3 xl:grid-cols-4 xl:grid-cols-5"></div>
                
                <div id="grid-id" ref={elRef} className={`-m-1 flex flex-wrap md:-m-2 grid grid-cols-${gridCols(screenSize)}`}>
                    {items &&
                        items.map((item, index) => {
                            if (
                                item.isFolder === false &&
                                item.contentType.indexOf('image') > -1 &&
                                props.setting.showVideos === true
                            ) {
                                return (
                                    
                                    <div
                                        key={'img-' + index}
                                        className={"cursor-pointer hover:contrast-10 flex lg:w-full xs:w-full md:w-full flex-wrap mx-auto w-100 "+ (props.setting.gap? "p-"+props.setting.gap : "") }
                                        onClick={()=>{setOpenGallery(true); setMaximize(Date.now().toString()); setOpenGalleryIx(index);}}>
                                            <ImageComponent
                                                imgClass={"h-auto max-w-full shadow-lg" + (props.setting.roundCorners ? " rounded-lg" : "")}
                                                userId={userId}
                                                item={item}
                                                w={size()}
                                                h={zoomLevel !==1 ? size(): null}
                                                q={q}
                                                loadingH={"60"}
                                            />
                                    </div>
                                );
                               
                            }
                            else if (
                                item.isFolder === false &&
                                item.contentType.indexOf('video') > -1
                            ) {
                                return (

                                    <div
                                        key={'img-' + index}
                                        className={"cursor-pointer hover:contrast-10 flex lg:w-full xs:w-full md:w-full flex-wrap mx-auto w-100 "+ (props.setting.gap? "p-"+props.setting.gap : "") }
                                        // onClick={()=>{setOpenGallery(true); setMaximize(Date.now().toString()); setOpenGalleryIx(index);}}
                                    >

                                        <VideoPlayer src={"api/video/" + item.id+"/manifest.mpd"}
                                                     hideControls={true} mute={true} type={item.contentType}></VideoPlayer>

                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </div>
        </>
    );
};

export default observer(BasicGalleryLayout);