import {flow} from "mobx";
import AppState from "./index";
import axios from "axios";

export const accountBillingUrl = '/api/Account/UpdateBillingLink';
export const getUserGalleryUrl = '/api/FileViewer/{userId}/{galleryId}';
export const getUserInfoUrl = '/api/Auth/User';
export const updateDirectoryUrl ='/api/Directory/UpdateDirectoryName';

export const getFileUploadUrl = (path, fileName, contentType, length) => {
    return `/api/LargeFileUpload/signed-url?path=${encodeURIComponent(path)}&fileName=${encodeURIComponent(fileName)}&contentType=${encodeURIComponent(contentType)}&length=${length}`;
}

export const get = async (url)=>{
        let token = AppState.authorization.getToken();
        let AuthHeader = 'Bearer ' + token;
        let response = await fetch(url, {
            method: 'GET',
            headers: {
                "Authorization": AuthHeader
            }
        });
       
        if (!response.ok && response.status === 401){            
            window.location ="/account/login"
        }
        
      return await response.json();       
};

export const post = async (url, data) => {
    let token = AppState.authorization.getToken();
    let AuthHeader = 'Bearer ' + token;
    
    let response = await fetch(url, {
        method: 'POST',
        headers: {
            "Authorization": AuthHeader,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    
    if (!response.ok && response.status === 401){            
        window.location ="/account/login"
    }
    
    return await response.json();       
}

// axios.interceptors.request.use(request => {
//     if (request.url.indexOf(window.location.host) > -1) {
//             let token = AppState.authorization.getToken();
//             request.headers['Authorization'] = 'Bearer ' + token;
//     }
// });

axios.interceptors.response.use(response => {
    // Do something with the response data
    if (response.status === 308) {
        console.log("Received 308 - Resume Incomplete");
        // Handle the response as you need
        return response;
    }
    return response;
}, error => {
    // Do something with the response error
    if (error.response && error.response.status === 308) {
        console.log("Received 308 - Resume Incomplete");        
        return Promise.resolve(error.response);
    }
    return Promise.reject(error);
});