import {useStore} from "../../models/RootStore";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";

const AccountPage = (props) => {
  const { setPageTitle, account } = useStore();
  
  const [billingLink, setBillingLink] = useState("test");
  
  useEffect(() => {
      setPageTitle("Account");
      account.getBillingLink().then((result) => {
            setBillingLink(result);
      })  
  }, []);

    return (
        <>
            <section className="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 lg:px-8">
                <div className="space-y-3 text-center">
                    <h1 className="text-3xl text-gray-800 font-semibold">
                        Account
                    </h1>                    
                </div>
                
                <div className="mt-14 gap-4 sm:grid sm:grid-cols-2 lg:grid-cols-3">
                    <a className="w-full block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500" href={billingLink}>Account information</a>
                    <a className="w-full block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500" href={billingLink+'/payment-methods'}>Add Payment Method </a>
                    <a className="w-full block rounded border border-indigo-600 bg-indigo-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500" href={billingLink+'/customer/update'}>Edit billing information</a>
                </div>
            </section>          
        </>
    )

}

export default observer(AccountPage);