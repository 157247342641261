import React, {Component, useEffect} from 'react';
import {useStore} from "../models/RootStore";
import {observer} from "mobx-react-lite";


const Home = () => {

    const { setPageTitle } = useStore();
    useEffect(() => {
        setPageTitle("Your All-in-One Image Management Solution");
    }, []);
    return (<>

            <section className="mt-24 mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
                <div className="text-center space-y-4">
                    <h1 className="text-gray-800 font-bold text-4xl md:text-5xl">
                        Effortlessly Organize and Access Your Images with Our
                        <span className="text-indigo-600"> Image Management Platform</span>
                    </h1>
                    <p className="text-gray-500 max-w-xl mx-auto leading-relaxed">
                        We make it easy for you to upload, organize, access and distribute all of your images in one convenient location.
                    </p>
                </div>
                <div className="mt-12 justify-center items-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex">
                    <a href="javascript:void(0)" className="px-10 py-3.5 w-full bg-indigo-600 text-white text-center rounded-md shadow-md block sm:w-auto">
                        Get started
                    </a>
                    <a href="javascript:void(0)"
                       className="px-10 py-3.5 w-full text-gray-500 text-center border rounded-md duration-300 hover:text-indigo-600 hover:shadow block sm:w-auto">
                        Try it out
                    </a>
                </div>
            </section>

            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
                            <div className="h-full text-center">
                                <img alt="testimonial"
                                     className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-100"
                                     src="/images/avatar1.png"/>
                                <p className="leading-relaxed">
                                    As a web developer, I'm always looking for ways to improve the speed and performance of my clients' websites. One area that can
                                    often be a bottleneck
                                    is the delivery of images. That's why I started using this cloud-based image management platform. It's helped me serve images
                                    faster and more efficiently, which has resulted in a noticeable improvement in the overall performance of my clients'
                                    websites..</p>
                                <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
                                <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">James Martinez</h2>
                                <p className="text-gray-500">Software Developer</p>

                            </div>
                        </div>
                        <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
                            <div className="h-full text-center">
                                <img alt="testimonial"
                                     className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-100"
                                     src="/images/avatar2.png"/>
                                <p className="leading-relaxed">As a professional photographer, I rely on a fast and reliable image management platform to store and
                                    access my photos. This platform has exceeded my expectations in every way. The interface is easy to use, the search function is
                                    fast and accurate, and the customer support team is always quick to help with any issues I've had. I wouldn't use any other
                                    platform.</p>
                                <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
                                <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">David Brown</h2>
                                <p className="text-gray-500">Professional Photographer</p>
                            </div>
                        </div>
                        <div className="lg:w-1/3 lg:mb-0 p-4">
                            <div className="h-full text-center">
                                <img alt="testimonial"
                                     className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-100"
                                     src="/images/avatar3.png"/>
                                <p className="leading-relaxed">As a small business owner, I don't have a lot of time to spend on image management. This platform has
                                    made it so easy for me to upload and organize my images, and the automatic backup feature gives me peace of mind knowing that my
                                    images are always safe and secure. I can't recommend it enough.</p>
                                <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
                                <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">Sarah Johnson</h2>
                                <p className="text-gray-500">CEO</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="text-gray-600 body-font">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col text-center w-full mb-20">
                        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">About us</h1>
                        <p class="lg:w-2/3 mx-auto leading-relaxed text-base">
                            We have seen significant usage growth over the past year
                            as more and more businesses have adopted our platform to streamline their image management processes.
                        </p>
                        <p className="lg:w-2/3 mt-2 mx-auto leading-relaxed text-base">
                            Our platform is designed to be user-friendly and intuitive, with a range of features that help teams easily upload, organize, and access
                            their
                            images. We have a strong focus on customer support and are dedicated to continuously improving our platform to meet the evolving needs of
                            our
                            users.
                        </p>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                            As a result of our focus on providing a high-quality product and excellent customer service, we have experienced significant growth in
                            usage
                            among a wide range of industries, including marketing, e-commerce, and design.
                        </p>
                        <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                            We are excited to continue this growth as we continue to develop and improve our platform, and we are committed to helping our customers
                            efficiently manage their images.
                        </p>
                    </div>
                    <div class="flex flex-wrap -m-4 text-center">
                        <div class="p-4 md:w-1/3 sm:w-1/2 w-full">
                            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                     class="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                    <path d="M8 17l4 4 4-4m-4-5v9"></path>
                                    <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path>
                                </svg>
                                <h2 class="title-font font-medium text-3xl text-gray-900">2.7M</h2>
                                <p class="leading-relaxed">Monthly downloads</p>
                            </div>
                        </div>
                        <div class="p-4 md:w-1/3 sm:w-1/2 w-full">
                            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                     class="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                    <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                                    <circle cx="9" cy="7" r="4"></circle>
                                    <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                                </svg>
                                <h2 class="title-font font-medium text-3xl text-gray-900">5.3K</h2>
                                <p class="leading-relaxed">Active users</p>
                            </div>
                        </div>
                        <div class="p-4 md:w-1/3 sm:w-1/2 w-full">
                            <div class="border-2 border-gray-200 px-4 py-6 rounded-lg">
                                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                     class="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                                    <path d="M3 18v-6a9 9 0 0118 0v6"></path>
                                    <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
                                </svg>
                                <h2 class="title-font font-medium text-3xl text-gray-900">39M</h2>
                                <p class="leading-relaxed">Files</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default observer(Home);