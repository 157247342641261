import axios from "axios";
import {useEffect, useState, Fragment} from "react";
import CreateFolderAction from "./CreateGalery/CreateFolder";
import {useStore} from "../../models/RootStore";
import ImageComponent from "../Gallery/ImageComponent";
import { Menu, Transition, Dialog } from '@headlessui/react'
import { ChevronDownIcon, EllipsisVerticalIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import dashjs from "dashjs";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
export function Row(props) {
    const {authorization,cnd} = useStore();
   
    const {item, index, setFolder} = props;
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [downloadSpeed, setDownloadSpeed] = useState("");
    const [isRemoved, setIsRemoved] = useState(false);
    const [isCreateGalleryModalOpen, setIsCreateGalleryModalOpen] = useState(false);
    
    const [isShowPreviewPopup, setIsShowPreviewPopup] = useState(false);
    const [previewFile, setPreviewFile] = useState({});
    const [user, setUser] = useState({});
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    let source = axios.CancelToken.source();

    useEffect(() => {
        authorization.getUser().then((user) => {
            setUser(user);
        })
        return () => {
            source.cancel();
        }
    }, []);

    const downloadFile = async () => {
        if (downloading) return;

        setDownloading(true);
        await axios({
            url: "/File?file=" + item.nameFull,
            method: "GET",
            responseType: "blob", // important,
            cancelToken: source.token,
            headers: { "Authorization": "Bearer " + authorization.getToken()},
            onDownloadProgress: (progressEvent) => {
                console.log(progressEvent);
                const total = item.size;
                const current = progressEvent.loaded;

                let percentCompleted = Math.floor(current / total * 100)

                setDownloadProgress(percentCompleted);

                let speed = progressEvent.rate; // in bytes
                let speedKb = speed / 1024;
                let speedMb = speedKb / 1024;

                let speedText = "";
                //show closest unit 
                if (speedMb > 1) {
                    speedText = speedMb.toFixed(2) + " Mb/s";
                } else if (speedKb > 1) {
                    speedText = (speedKb.toFixed(2) + " Kb/s");
                } else {
                    speedText = setDownloadSpeed((speed || "0") + " b/s");
                }

                //estimate time left
                let timeLeft = (total - current) / speed;
                let timeLeftSeconds = Math.floor(timeLeft % 60);
                let timeLeftMinutes = Math.floor((timeLeft / 60) % 60);
                let timeLeftHours = Math.floor((timeLeft / 3600) % 24);

                //show time left
                if (timeLeftHours > 0) {
                    setDownloadSpeed(speedText + " (" + timeLeftHours + "h " + timeLeftMinutes + "m " + timeLeftSeconds + "s)");
                } else if (timeLeftMinutes > 0) {
                    setDownloadSpeed(speedText + " (" + timeLeftMinutes + "m " + timeLeftSeconds + "s)");
                } else {
                    setDownloadSpeed(speedText + " (" + timeLeftSeconds + "s)");
                }
            }
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], {type: item.contentType}));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", item.name); //or any other extension
                document.body.appendChild(link);
                link.click();
                setDownloading(false)
            })
            .catch(error => {
                setDownloading(false)
                return [];
            });
    }
    const removeFile = async () => {

        await axios({
            url: "api/FileRemove?folder=" + item.nameFull,
            method: "DELETE",
            headers: { "Authorization": "Bearer " + authorization.getToken()},
        })
            .then(response => {
                console.log(response);
                setIsRemoved(true);
            })
            .catch(error => {
                return [];
            });
    }
    const renderIcon = (item) => {
        if (item.isFolder) {
            return <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" width="20px" viewBox="0 0 512 512"><title>Folder Open</title>
                <path d="M64 192v-72a40 40 0 0140-40h75.89a40 40 0 0122.19 6.72l27.84 18.56a40 40 0 0022.19 6.72H408a40 40 0 0140 40v40" fill="none" stroke="currentColor"
                      stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
                <path d="M479.9 226.55L463.68 392a40 40 0 01-39.93 40H88.25a40 40 0 01-39.93-40L32.1 226.55A32 32 0 0164 192h384.1a32 32 0 0131.8 34.55z" fill="none"
                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/>
            </svg>;
        } else {

            return (
                <>
                    <div className="hidden md:flex " key={item.nameFull+'md'} href={"/api/image/" + item.id} onClick={downloadFile} target="_blank"
                        download={item.name}>{downloading ? downloadSpeed : "Download"}</div>
                    
                    <div className="md:hidden m-2" key={item.nameFull+'lg'} href={"/api/image/" + item.id} onClick={downloadFile} target="_blank"
                         download={item.name}>
                        <ArrowDownTrayIcon width="15" height="15"></ArrowDownTrayIcon>
                    </div>
                    </>
            );

        }
    }
    const renderPreview = (item) => {
        if (item != null && user && user.userId && item.contentType && item.contentType.indexOf("image") > -1) {
            return (
                <ImageComponent
                    imgClass={"rounded-lg max-h-[91px]"}
                    userId={user.userId}
                    item={item}
                    w={300}
                    h={300}
                    loadingH={92}
                    loadingW={92}
                    q={80}
                ></ImageComponent>
        )
        }
        if (item != null && user && user.userId && item.contentType && item.contentType.indexOf("video") > -1) {
            console.log(item)
            return (
                <VideoPlayer src={"api/video/" + item.id+"/manifest.mpd"} hideControls={true} type={item.contentType}></VideoPlayer>
            )
            
        }
        else if(!item.isFolder) {
            return <div>{item.contentType}</div>
        }
        return (<> </>)
    }
    const renderSize = (sizeBytes) => {
        if (sizeBytes === 0) return "";
        const units = ["B", "KB", "MB", "GB", "TB"];
        const unit = units[Math.floor(Math.log2(sizeBytes) / 10)];
        return (sizeBytes >> (10 * units.indexOf(unit))) + unit;
    }
    const renderFolderLinks = (link) => {
        link = link || "";
        const linkItems = link.split("/");
        linkItems.unshift("..");

        return linkItems.map((item, index) => {
            let link = linkItems.slice(0, index + 1).join("/");
            link = link.replace("..", "").substring(1, link.length - 1);
            return <span>
                <a onClick={() => setFolder(link)} aria-disabled={index > linkItems.length - 1} title={link} className="btn link-primary">{item}</a>
                {index > 0 && <span>/</span>}
            </span>
        });
    }

    return (<>
            <RenameDirectoryModal folder={item} isOpen={isRenameModalOpen} onClose={()=>setIsRenameModalOpen(false)} />
            
            {/*{isShowPreviewPopup && <PreviewPopup item={item} file={previewFile} isOpen={isShowPreviewPopup} hideModal={()=>setIsShowPreviewPopup(false)}/> }*/}
            {index === 0 && item.isFolder ?
                <tr key={index+'-'+item.id } className="bg-gray-200 border-b border-gray-200">
                    <td colSpan="6" className="p-5">
                        <div className="flex flex-row">
                            <div className="sm:basis-1/2 basis-full">
                                <b>{renderFolderLinks(item.nameFull)}</b>
                            </div>                            
                            <div className="sm:basis-1/2 basis-full flex flex-row-reverse">
                                <CreateFolderAction folder={item.nameFull} id={item.id}/>
                            </div>
                        </div>                       
                    </td>
                </tr> :
                !isRemoved &&
                <tr className={"bg-white border-b dark:bg-gray-800 dark:border-gray-700 transition-all duration-300 easy " + (index === 0 ? "bg-gray-100" : "")}
                    key={item.id}>
                    <td className="pl-2 w-[100px]">
                        {renderPreview(item)}</td>
                    <td className="pl-2">{renderIcon(item)}</td>


                    <td scope="row" className="py-4 pr-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {index === 0 ?<b>{renderFolderLinks(item.nameFull)}</b> : 
                            item.isFolder ?
                            <a onClick={a => setFolder(item.nameFull)}>{item.name}</a> : 
                            <a onClick={e=>{setPreviewFile(item); setIsShowPreviewPopup(true)}}>{item.name}</a>
                        }
                    </td>
                    <td className="py-4 px-6">{renderSize(item.size)}</td>
                    {/*<td className="py-4 px-6 hidden md:hidden">{item.timeCreated}</td>*/}
                    <td className="py-4 px-6">
                        <div className="flex space-x-4 text-sm">
                            <div className="flex align-items-end">                                
                                <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                            <EllipsisVerticalIcon
                                                className="ml-2 -mr-1 h-5 w-5 text-violet-900 hover:text-violet-100"
                                                aria-hidden="true"
                                            />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                            <div className="px-1 py-1 ">
                                                
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button onClick={()=>setIsRenameModalOpen(true)}
                                                            className={`${
                                                                active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                        >
                                                            {active ? (
                                                                <EditActiveIcon
                                                                    className="mr-2 h-5 w-5"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <EditInactiveIcon
                                                                    className="mr-2 h-5 w-5"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            Rename
                                                        </button>
                                                    )}
                                                </Menu.Item>                                                
                                            </div>
                                            
                                            <div className="px-1 py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button onClick={removeFile}
                                                            className={`${
                                                                active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                        >
                                                            {active ? (
                                                                <DeleteActiveIcon
                                                                    className="mr-2 h-5 w-5 text-violet-400"
                                                                    aria-hidden="true"
                                                                />
                                                            ) : (
                                                                <DeleteInactiveIcon
                                                                    className="mr-2 h-5 w-5 text-violet-400"
                                                                    aria-hidden="true"
                                                                />
                                                            )}
                                                            Delete
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>

                            </div>
                        </div>
                    </td>
                </tr>

            }
            {downloading &&
                <tr>
                    <td colSpan={6} className="w-full bg-gray-50 h-0.5 dark:bg-gray-700">
                        <div className="bg-blue-600 h-2.5" style={{width: downloadProgress + "%"}}></div>
                    </td>
                </tr>
            }
        </>
    )
}

function EditInactiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 13V16H7L16 7L13 4L4 13Z"
                fill="#EDE9FE"
                stroke="#A78BFA"
                strokeWidth="2"
            />
        </svg>
    )
}

function EditActiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 13V16H7L16 7L13 4L4 13Z"
                fill="#8B5CF6"
                stroke="#C4B5FD"
                strokeWidth="2"
            />
        </svg>
    )
}



function DeleteInactiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="5"
                y="6"
                width="10"
                height="10"
                fill="#EDE9FE"
                stroke="#A78BFA"
                strokeWidth="2"
            />
            <path d="M3 6H17" stroke="#A78BFA" strokeWidth="2" />
            <path d="M8 6V4H12V6" stroke="#A78BFA" strokeWidth="2" />
        </svg>
    )
}

function DeleteActiveIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="5"
                y="6"
                width="10"
                height="10"
                fill="#8B5CF6"
                stroke="#C4B5FD"
                strokeWidth="2"
            />
            <path d="M3 6H17" stroke="#C4B5FD" strokeWidth="2" />
            <path d="M8 6V4H12V6" stroke="#C4B5FD" strokeWidth="2" />
        </svg>
    )
}

function RenameDirectoryModal(props) {
    let [isOpen, setIsOpen] = useState(false)
    let [newName, setNewName] = useState('')
    
    let {directory} = useStore();
    
    useEffect(() => {
            setIsOpen(props.isOpen);
            setNewName(props.folder.name);
    }, [props.isOpen])

    const closeModal = () => {
        setIsOpen(false);
        props.onClose && props.onClose();     
    }

    function renameItem() {

        if(props.folder.isFolder){
            directory.updateDirectoryName(props.folder.nameFull, newName);
        }
        
        closeModal();
    }

    return (<>
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                            <Dialog.Title
                                as="h3"
                                className="text-lg font-medium leading-6 text-gray-900"
                            >
                                Rename {props.folder.isFolder ? "Folder" : "File"} <span className="bg-yellow-100">{props.folder.name}</span>
                            </Dialog.Title>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                   <input className="border border-gray-300 p-2 rounded-md w-full" type="text" value={newName} onChange={(e) => setNewName(e.target.value)} />
                                </p>
                            </div>

                            <div className="mt-4 flex flex-row js">
                                <div className="basis-1/2">
                                    <button
                                        type="button"
                                        className="rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                </div>
                                <div className="basis-1/2">
                                    <button
                                        type="button"
                                        className="float-right rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                        onClick={renameItem}
                                    >
                                        Accept
                                    </button>
                                </div>
                                
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
</>
    )
}