import {getRoot, types} from "mobx-state-tree";
import {accountBillingUrl, get} from "../api";
import {flow} from "mobx";

export const AccountStore = types
    .model({
        stripeBaseUrl: types.maybe(types.string),
    })
.actions(self => {
   
    // get root store
    //const root = getRoot(self)
    
    const getBillingLink = flow(function*() {
        self.stripeBaseUrl ="test"
        const root = getRoot(self)
        let result = yield get(accountBillingUrl, root.authorization.getToken());
        return result;
    });
    
    return { getBillingLink };
   
});