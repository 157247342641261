import React, {Component, useEffect, useState} from 'react';
import {NavMenu} from './NavMenu';
import {useLocation, useSearchParams} from "react-router-dom";
import {useStore} from "../models/RootStore";


export const Layout = (props)=>{
    const {iframe,setIframe} = useStore();
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        if (searchParams.get("iframe") === "true") {
            setIframe(true)
        }
    },[searchParams])
    return (
        <div>
            { iframe ? null :
                <NavMenu/>
            }
            <div>
                <div className="w-100 container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">

                    {props.children}

                </div>
            </div>
        </div>
    );
    
}

